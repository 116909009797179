<template>
  <a-form :model="formState" name="basic" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }" autocomplete="off"
    @finish="onFinish" @finishFailed="onFinishFailed">
    <a-form-item label="" name="phone"
      :rules="[{ required: true, message: '请输入手机号!' }, { len: 11, message: '请输入正确的格式!' }]">
      <a-input size="large" prefix="+86" :maxlength="11" type="tel" placeholder="请输入手机号"
        v-model:value="formState.phone" />
    </a-form-item>

    <a-form-item label="" name="password" :rules="[{ required: true, message: '请输入密码!' }]">
      <a-input-password size="large" placeholder="请输入密码" v-model:value="formState.password" />
    </a-form-item>

    <div class="btns">
      <a-button type="link">忘记密码?</a-button>
    </div>

    <!-- <a-form-item name="remember" :wrapper-col="{ offset: 0, span: 24 }">
      <a-checkbox v-model:checked="formState.remember">
        <div class="tip">
          我已阅读并同意
          <a href="#" class="span">《企政牛平台服务协议》</a>、
          <a href="#" class="span">《企政牛隐私权政策》</a>
        </div>
      </a-checkbox>
    </a-form-item> -->

    <a-form-item :wrapper-col="{ offset: 0, span: 24 }">
      <a-button :loading="loading" type="primary" size="large" block html-type="submit">立即登录</a-button>
    </a-form-item>
  </a-form>
</template>
<script>
import service from '@/service/service';
import { message } from 'ant-design-vue';
import { mapActions } from 'vuex';

export default {
  props: ['isAdmin'],
  data() {
    return {
      formState: {
        phone: '',
        password: '',
        // remember: false,
      },
      loading: false,
    }
  },
  methods: {
    ...mapActions(['getUserinfo']),
    async onFinish(values) {
      // if (!values.remember) {
      //   message.error('请先阅读协议');
      //   return;
      // }
      // delete values.remember;
      this.loading = true;
      const res = await service.login({ ...values });
      this.loading = false;
      if (res.code) {
        
        window.sessionStorage.setItem('token', res.data);
        this.getUserinfo().then((res) => {
          if (this.$route.query?.rewitchPath) {
            this.$router.replace(this.$route.query?.rewitchPath);
          }
          else {
            if (this.isAdmin) {
              console.log(res)
              if(res.role_id == 1){
                message.success('登录成功!');
                this.$router.push('/platform/home');
              }else{
                message.error('您不是管理员!');
              }
              //
            }
            else {
              message.success('登录成功!');
              this.$router.push('/work/home')
            }
          }
        });
      }
    },
    onFinishFailed(err) {
      console.log(err);
    }
  }
}
</script>
<style lang="less" scoped>
.btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 24px;
}
</style>